//
// UpgradeModal.tsx - Upgrade Dialog Component
//

"use client";

import RocketSvg from "@components/GenericElements/SVG/RocketSvg";
import { useGetUser } from "@custom-hooks/useApi";
import { Dialog } from "@headlessui/react";
import { Button } from "@mui/material";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";

interface UpgradeDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

/**
 * UpgradeDialog Component
 *
 * Renders a dialog to prompt users to subscribe for updates on the SQLite Cloud Pro launch.
 * Uses PostHog to capture subscription-related analytics events.
 *
 * @param {boolean} isOpen - Controls whether the dialog is visible.
 * @param {(value: boolean) => void} setIsOpen - Function to set dialog visibility.
 * @returns {JSX.Element} The UpgradeDialog component.
 */
function UpgradeDialog({ isOpen, setIsOpen }: UpgradeDialogProps) {
  const posthog = usePostHog();
  const { user: userInfo } = useGetUser();
  const [isSubscribed, setIsSubscribed] = useState(false);

  // Handle subscription action and track event with PostHog
  const handleSub = () => {
    posthog.capture("subscribe:notify_pro");
    setIsSubscribed(true);
  };

  // Track initial upgrade click event
  useEffect(() => {
    if (isOpen) {
      posthog.capture("upgrade_click");
    }
  }, [isOpen]);

  return (
    <Dialog
      className="tw-relative tw-z-[1205]"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto tw-bg-mediumblue tw-bg-opacity-20">
        <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
          <Dialog.Panel className="tw-w-full tw-max-w-lg tw-transform tw-overflow-hidden tw-rounded-2xl tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-shadow-xl tw-transition-all">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <RocketSvg onClick={null} />
              {!isSubscribed ? (
                <>
                  <div className="tw-mb-6 tw-mt-3">
                    <Dialog.Title className="tw-mb-6 tw-text-h4-desk">
                      Upgrade to SQLite Cloud Pro
                    </Dialog.Title>
                    <Dialog.Description className="tw-mt-2 tw-text-13px-regular tw-text-gray-700">
                      Hey {userInfo?.name}! Thank you for your interest in
                      SQLite Cloud Pro.
                    </Dialog.Description>
                    <Dialog.Description className="tw-mt-2 tw-text-13px-regular tw-text-gray-700">
                      SQLite Cloud is currently in beta, but we will launch our
                      Pro plan (including local-first sync) in the coming weeks.
                    </Dialog.Description>
                    <Dialog.Description className="tw-mt-2 tw-text-13px-regular tw-text-gray-700">
                      Subscribe to get notified when we launch SQLite Cloud Pro!
                    </Dialog.Description>
                  </div>
                  <div className="tw-flex tw-w-full tw-justify-between">
                    <Button
                      onClick={() => setIsOpen(false)}
                      sx={{
                        backgroundColor: "#D0E2F8",
                        color: "#1F1F1F",
                        "&:hover": {
                          backgroundColor: "#B0C2D8",
                          color: "#1F1F1F",
                        },
                      }}
                    >
                      Close
                    </Button>
                    <Button onClick={handleSub} className="tw-mt-4">
                      Subscribe
                    </Button>
                  </div>
                </>
              ) : (
                <div className="tw-mb-3 tw-mt-3">
                  <Dialog.Title className="tw-mb-6 tw-text-h4-desk">
                    Upgrade to SQLite Cloud Pro
                  </Dialog.Title>
                  <Dialog.Description className="tw-mb-6 tw-text-13px-regular tw-text-gray-700">
                    Thanks for subscribing! We'll notify you when SQLite Cloud
                    Pro is ready.
                  </Dialog.Description>
                  <Button onClick={() => setIsOpen(false)}>Close</Button>
                </div>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}

UpgradeDialog.displayName = "UpgradeDialog";

export { UpgradeDialog };
