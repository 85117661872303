//
// parseSizeDescription.ts
//

/**
 * Helper function to parse size description into individual CPU, RAM, and storage fields.
 *
 * @param {string} sizeDescription - The size description string, e.g., "0.5vCPU / 512MB / 5GB".
 *
 * @returns {{ cpu: string; ram: string; storage: string }} Parsed fields for CPU, RAM, and storage.
 */
export function parseSizeDescription(sizeDescription: string): {
  cpu: string;
  ram: string;
  storage: string;
} {
  const [cpu, ram, storage] = sizeDescription.split(" / ");
  return { cpu, ram, storage };
}
