//
// useGetUserPlanInfo.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectPlan } from "@data-types/plan-info-types";
import { parseSizeDescription } from "../utils/parseSizeDescription";

/**
 * Custom hook to fetch and process user plan information.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {FetchHookResult<ProjectPlan>} The result object with the project plan
 *   (or `undefined` if no regions are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetUserPlanInfo(
  refreshInterval?: number
): FetchHookResult<ProjectPlan> {
  const { data, hasData, ...fetchDetails } = useFetchData<any>(
    true,
    `/api/plan`,
    "useGetPlanInfo",
    "backend",
    {
      refreshInterval,
      revalidateOnFocus: false,
    }
  );

  // Parse the plan information if data is available
  const planInfo: ProjectPlan | undefined = hasData
    ? {
        ...data[0],
        ...parseSizeDescription(data[0].size_description),
      }
    : undefined;

  return {
    data: planInfo,
    hasData,
    ...fetchDetails,
  };
}
