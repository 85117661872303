//
// useGetUserProjects.ts
//

import { useFetchData } from "@custom-hooks/useFetchData";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Projects, RawProject, RawProjects } from "@data-types/projects-types";
import { useMemo } from "react";

/**
 * Custom hook to fetch project data and provide various states for managing the display.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing projects data.
 *
 * @returns {FetchHookResult<Projects>} The result object containing the list of parsed project data, including a
 *   `regionsList` extracted from the project's `regions` JSON field (or `undefined` if no project data is available),
 *   along with UI state indicators and a function to manage fetched data.
 */
export function useGetUserProjects(
  refreshInterval?: number
): FetchHookResult<Projects> {
  const { data, hasData, ...fetchDetails } = useFetchData<RawProjects>(
    true,
    `/api/projects`,
    "useGetUserProjects",
    "backend",
    {
      refreshInterval,
    }
  );

  const parsedData: Projects | undefined = useMemo(() => {
    if (!hasData || !data) return undefined;

    return data
      .map((project: RawProject) => {
        try {
          const regionsList = JSON.parse(project.regions);
          return {
            ...project,
            regionsList: regionsList[0] ? regionsList : [],
          };
        } catch {
          return { ...project, regionsList: [] };
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [hasData, data]);

  return {
    data: parsedData,
    hasData,
    ...fetchDetails,
  };
}
