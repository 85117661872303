import { nodeIsWorking, useGetProjectNodes } from "@custom-hooks/nodes";
import { ApiKey } from "@data-types/api-key-types";
import { Database } from "@data-types/databases-types";
import { Project } from "@data-types/projects-types";
import { useMemo } from "react";

type UseErrorMessagesProps = {
  project: {
    isError: boolean;
    selected: string | undefined;
    isEmpty: boolean;
    options: {
      options: Record<string, Project>;
      sortedKeys: string[];
    };
  };
  database: {
    isError: boolean;
    selected: string | undefined;
    options: {
      options: Record<string, Database>;
      sortedKeys: string[];
    };
    isEmpty: boolean;
  };
  user: {
    isError: boolean;
  };
  apiKey: {
    isError: boolean;
    selected: string | undefined;
    options: { options: Record<string, ApiKey>; sortedKeys: string[] };
    isEmpty: boolean;
  };
  connectionString: string;
};

type ErrorMessages = {
  project?: string;
  database?: string;
  user?: string;
  apiKey?: string;
  connectionString?: string;
};

/**
 * Hook to generate error messages for various project-related resources.
 *
 * @param {object} project - Project-related information.
 * @param {boolean} project.isError - Indicates if there was an error fetching the project data.
 * @param {string | undefined} project.selected - The ID of the selected project.
 * @param {boolean} project.isEmpty - Indicates if no projects are available.
 * @param {object} project.options - Available project options.
 *
 * @param {object} database - Database-related information.
 * @param {boolean} database.isError - Indicates if there was an error fetching the databases.
 * @param {string | undefined} database.selected - The name of the selected database.
 * @param {object} database.options - Available database options.
 * @param {boolean} database.isEmpty - Indicates if no databases are available for the project.
 *
 * @param {object} user - User-related information.
 * @param {boolean} user.isError - Indicates if there was an error fetching the users.
 *
 * @param {object} apiKey - API key-related information.
 * @param {boolean} apiKey.isError - Indicates if there was an error fetching the API keys.
 * @param {string | undefined} apiKey.selected - The name of the selected API key.
 * @param {object} apiKey.options - Available API key options.
 * @param {boolean} apiKey.isEmpty - Indicates if no API keys are available for the selected user.
 *
 * @param {string} connectionString - Generated connection string.
 *
 * @returns {ErrorMessages} - An object containing error messages for each resource type.
 * @property {string | undefined} project - The error message for the project, if any.
 * @property {string | undefined} database - The error message for the database, if any.
 * @property {string | undefined} user - The error message for the user, if any.
 * @property {string | undefined} apiKey - The error message for the API key, if any.
 * @property {string | undefined} connectionString - The error message for the connection string, if any.
 */
export const useGetErrorMessages = ({
  project,
  database,
  user,
  apiKey,
  connectionString,
}: UseErrorMessagesProps): ErrorMessages => {
  const {
    data: nodes,
    hasData: availableNodes,
    isLoading: loadingNodes,
  } = useGetProjectNodes(project.selected ?? "");

  const projectError = useMemo(() => {
    if (project.isError) {
      return "Network error: Unable to fetch projects.";
    }

    if (project.selected) {
      if (project.options.options[project.selected].nodes_count === 0) {
        return "Error: No region for this project.";
      }

      if (!loadingNodes && availableNodes && nodes) {
        for (const node of nodes) {
          if (!nodeIsWorking(node)) {
            return "Error: Project is offline.";
          }
        }
      }
    }

    if (project.isEmpty) {
      return "Error: No project found.";
    }

    return undefined;
  }, [project]);

  const databaseError = useMemo(() => {
    if (database.isError) {
      return "Network error: Unable to fetch databases.";
    }

    if (database.selected) {
      switch (database.options.options[database.selected]?.status) {
        case 0:
          return "Error: File is not a valid SQLite database.";
        case 2:
          return "Error: Database is disabled.";
        case 3:
          return "Error: Database in maintenance mode.";
        case 4:
          return "Error: Database is in error state.";
      }
    }

    if (database.isEmpty) {
      return "Error: No database found for the selected project.";
    }

    return undefined;
  }, [database]);

  const userError = useMemo(() => {
    if (user.isError) {
      return "Network error: Unable to fetch users.";
    }

    return undefined;
  }, [user]);

  const apiKeyError = useMemo(() => {
    if (apiKey.isError) {
      return "Network error: Unable to fetch API keys.";
    }

    if (apiKey.selected) {
      const expirationDate =
        apiKey.options.options[apiKey.selected]?.expiration_date;
      const currentDate = new Date();

      if (expirationDate && new Date(expirationDate) < currentDate) {
        return "Error: Selected API key is expired.";
      }
    }

    if (apiKey.isEmpty) {
      return "Error: No API key found for the selected user.";
    }

    return undefined;
  }, [apiKey]);

  return useMemo(
    () => ({
      project: projectError,
      database: databaseError,
      user: userError,
      apiKey: apiKeyError,
      connectionString:
        connectionString === ""
          ? "Error: Failed to generate connection string."
          : undefined,
    }),
    [projectError, databaseError, userError, apiKeyError, connectionString]
  );
};
