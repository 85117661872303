"use client";

import * as Headless from "@headlessui/react";
import DownArrowIcon from "@layouts/svg-icon/down-arrow-icon.svg";
import UpArrowIcon from "@layouts/svg-icon/up-arrow-icon.svg";
import clsx from "clsx";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Description, ErrorMessage, Field, Label } from "./fieldset";

// #region Listbox

//
// types
//
type Sizes = "large" | "medium" | "small" | "custom";
type StyleItem = string[];

type SizeStyles = {
  [K in Sizes]: StyleItem;
};

interface AppearanceStyles {
  typography: {
    placeholder: StyleItem;
    button: StyleItem;
    selectedOption: StyleItem;
    unselectedOption: StyleItem;
  };
  border: {
    buttonFocused: StyleItem;
    selectedOption: StyleItem;
    options: StyleItem;
  };
  background: {
    button: StyleItem;
    selectedOption: StyleItem;
    options: StyleItem;
  };
  icon: {
    enabled: StyleItem;
    disabled: StyleItem;
  };
}

//
// Component Styles
//

const sizeStyles: SizeStyles = {
  small: [
    "tw-h-[2.375rem] tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-1px)]",
  ],
  medium: [
    "tw-h-12 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-3px)]",
  ],
  large: [
    "tw-h-16 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[7])-3px)]",
  ],
  custom: [],
};

const appearanceStyles: AppearanceStyles = {
  typography: {
    placeholder: [
      // font
      "tw-text-14px-regular",
      // color
      "tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark tw-opacity-60",
      // disabled
      "data-[disabled]:tw-text-interface-gray-light dark:data-[disabled]:tw-text-interface-gray-dark",
    ],
    button: [
      // font
      "tw-text-14px-regular",
      // color
      "tw-text-text-title-light dark:tw-text-text-title-dark",
      // disabled
      "group-data-[disabled]:tw-text-interface-gray-light dark:group-data-[disabled]:tw-text-interface-gray-dark",
    ],
    selectedOption: [
      // font
      "tw-text-14px-book",
      // color
      "tw-text-text-title-light dark:tw-text-text-title-dark",
    ],
    unselectedOption: [
      // font
      "tw-text-14px-regular",
      // color
      "tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark",
      // focus
      "data-[focus]:tw-text-text-body-light dark:data-[focus]:tw-text-text-body-dark data-[focus]:tw-opacity-60 ",
    ],
  },
  border: {
    buttonFocused: [
      "after:tw-ring-2 after:tw-ring-brandBlues-brandSecondary-light",
    ],
    selectedOption: [
      // border
      "tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark",
      // hover
      "group-data-[hover]:tw-border-text-title-light group-data-[hover]:dark:tw-border-text-title-dark",
      // active
      "group-data-[active]:tw-border-text-title-light dark:group-data-[active]:tw-border-text-title-dark",
      // invalid
      "tw-group-data-[invalid]:dark:tw-border-semantics-error-dark tw-group-data-[invalid]:data-[hover]:tw-border-semantics-error-dark group-data-[invalid]:group-data-[hover]:tw-border-semantics-error-light group-data-[invalid]:tw-border-semantics-error-light",
      // disabled
      "group-data-[disabled]:tw-border-interface-divider-light data-[hover]:group-data-[disabled]:tw-border-interface-divider-light group-data-[disabled]:dark:tw-border-interface-divider-dark dark:data-[hover]:group-data-[disabled]:tw-border-interface-divider-dark",
    ],
    options: [
      // border
      "tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark",
      // hover
      "group-data-[hover]:tw-border-text-title-light dark:group-data-[hover]:tw-border-text-title-dark",
      // active
      "group-data-[active]:tw-border-text-title-light dark:group-data-[active]:tw-border-text-title-dark",
    ],
  },
  background: {
    button: [
      "before:tw-bg-interface-card-light before:data-[disabled]:tw-bg-interface-card-light",
    ],
    selectedOption: [
      "tw-bg-transparent dark:tw-bg-interface-card-dark dark:group-data-[disabled]:tw-bg-interface-card-dark",
    ],
    options: ["tw-bg-interface-card-light dark:tw-bg-interface-card-dark"],
  },
  icon: {
    enabled: ["tw-text-text-body-light dark:tw-text-text-body-dark"],
    disabled: ["tw-text-interface-gray-light dark:tw-text-interface-gray-dark"],
  },
};

//
// Listbox Props
//

interface ListboxBaseProps<T>
  extends Omit<Headless.ListboxProps<typeof Fragment, T>, "as" | "multiple"> {
  className?: string;
  placeholder?: React.ReactNode;
  autoFocus?: boolean;
  "aria-label"?: string;
  children?: React.ReactNode[];
  showLoader?: boolean;
}

type ListboxSizeProps =
  | { size: Exclude<Sizes, "custom">; customSize?: never }
  | { size: "custom"; customSize: StyleItem };

type ListboxStyleProps =
  | { style: "default"; customStyle?: never }
  | { style: "custom"; customStyle: AppearanceStyles };

export type ListboxProps<T> = ListboxBaseProps<T> &
  ListboxSizeProps &
  ListboxStyleProps;

export function Listbox<T>({
  className,
  placeholder,
  autoFocus,
  "aria-label": ariaLabel,
  children: options,
  size,
  customSize,
  style,
  customStyle,
  showLoader,
  ...props
}: ListboxProps<T>) {
  const styles = style === "default" ? appearanceStyles : customStyle;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Headless.Listbox {...props} multiple={false}>
      {({ open }) => (
        <>
          <Headless.ListboxButton
            ref={buttonRef}
            autoFocus={autoFocus}
            data-slot="control"
            aria-label={ariaLabel}
            className={clsx([
              className,
              // Basic layout
              "tw-group tw-relative tw-block tw-w-full",
              // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
              "before:tw-absolute before:tw-inset-px before:tw-rounded-[calc(theme(borderRadius.lg)-1px)]",
              // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
              "dark:before:tw-hidden",
              // Hide default focus styles
              "focus:tw-outline-none",
              // Focus ring
              "after:tw-pointer-events-none after:tw-absolute after:tw-inset-0 after:tw-rounded-lg after:tw-ring-inset",
              open && styles.border.buttonFocused,
              // Disabled state
              "data-[disabled]:tw-opacity-50  before:data-[disabled]:tw-shadow-none",
              // Color transitions
              "tw-transition-colors tw-duration-200 data-[focus]:tw-transition-colors data-[focus]:tw-duration-200",
              // Background colors
              styles.background.button,
            ])}
          >
            <Headless.ListboxSelectedOption
              as="span"
              options={options}
              placeholder={
                placeholder && (
                  <span
                    className={clsx(
                      "tw-block tw-truncate",
                      styles.typography.placeholder
                    )}
                  >
                    {placeholder}
                  </span>
                )
              }
              className={clsx([
                // Basic layout
                "tw-relative tw-block tw-w-full tw-appearance-none tw-rounded-lg",
                size !== "custom" ? sizeStyles[size] : customSize,
                "tw-flex tw-items-center tw-text-left",
                // Set minimum height for when no value is selected
                "tw-min-h-11 sm:tw-min-h-9",
                // Horizontal padding
                "tw-pl-[calc(theme(spacing[3.5])-1px)] tw-pr-[calc(theme(spacing.8)-1px)] sm:tw-pl-[calc(theme(spacing.3)-1px)]",
                // Typography
                styles.typography.button,
                // Background color
                styles.background.selectedOption,
                // Border
                styles.border.selectedOption,
                // Disabled state
                "group-data-[disabled]:tw-opacity-100 ",
                // Color transitions
                "group-data-[hover]:tw-transition-colors group-data-[hover]:tw-duration-200",
              ])}
            />
            <div
              className={clsx(
                "tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2",
                props.disabled ? styles.icon.disabled : styles.icon.enabled
              )}
            >
              {showLoader ? (
                <svg
                  aria-hidden="true"
                  className="tw-mr-1 tw-h-5 tw-w-5 tw-animate-spin tw-fill-brandBlues-brandSecondary-light tw-text-brandBlues-pale-light dark:tw-fill-brandBlues-brandSecondary-dark dark:tw-text-brandBlues-pale-dark"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : open ? (
                <UpArrowIcon />
              ) : (
                <DownArrowIcon />
              )}
            </div>
          </Headless.ListboxButton>
          <Headless.ListboxOptions
            transition
            anchor="bottom start"
            className={clsx(
              // Anchor positioning
              "tw-z-50 tw-mt-1",
              // Base styles
              "tw-isolate tw-select-none tw-scroll-py-1 tw-space-y-2 tw-rounded-xl tw-p-3",
              // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
              "tw-focus:tw-outline-none tw-outline tw-outline-1 tw-outline-transparent",
              // Handle scrolling when menu won't fit in viewport
              "tw-overflow-y-scroll tw-overscroll-contain",
              // Popover background
              styles.background.options,
              "tw-backdrop-blur-xl",
              // Transitions
              "tw-transition-opacity tw-duration-100 tw-ease-in data-[transition]:tw-pointer-events-none data-[closed]:data-[leave]:tw-opacity-0 group-data-[hover]:tw-transition-colors group-data-[hover]:tw-duration-200",
              // Border
              styles.border.options
            )}
            style={{ width: buttonWidth || "auto" }}
          >
            <>
              {React.Children.map(options, (child, index) => {
                if (React.isValidElement<ListboxOptionProps<T>>(child)) {
                  return React.cloneElement(child, {
                    key: child.key || index,
                    getTypography: (selected: boolean) => {
                      return selected
                        ? styles.typography.selectedOption
                        : styles.typography.unselectedOption;
                    },
                  });
                }

                return child;
              })}
            </>
          </Headless.ListboxOptions>
        </>
      )}
    </Headless.Listbox>
  );
}

type ListboxOptionProps<T> = {
  className?: string;
  children?: React.ReactNode;
  getTypography?: (selected: boolean) => StyleItem;
} & Omit<Headless.ListboxOptionProps<"div", T>, "as" | "className">;

export function ListboxOption<T>({
  children,
  className,
  getTypography,
  ...props
}: ListboxOptionProps<T>) {
  let sharedClasses = clsx(
    // Base
    "tw-flex tw-min-w-0 tw-cursor-pointer tw-items-center",
    // Icons
    "[&>[data-slot=icon]]:tw-size-5 [&>[data-slot=icon]]:tw-shrink-0 sm:[&>[data-slot=icon]]:tw-size-4",
    "[&>[data-slot=icon]]:tw-text-zinc-500 [&>[data-slot=icon]]:group-data-[focus]/option:tw-text-white [&>[data-slot=icon]]:dark:tw-text-zinc-400",
    "forced-colors:[&>[data-slot=icon]]:tw-text-[CanvasText] forced-colors:[&>[data-slot=icon]]:group-data-[focus]/option:tw-text-[Canvas]",
    // Avatars
    "[&>[data-slot=avatar]]:-tw-mx-0.5 [&>[data-slot=avatar]]:tw-size-6 sm:[&>[data-slot=avatar]]:tw-size-5"
  );

  return (
    <Headless.ListboxOption as={Fragment} {...props}>
      {({ selectedOption, selected }) => {
        if (selectedOption) {
          return (
            <div className={clsx(className, sharedClasses)}>{children}</div>
          );
        }

        return (
          <div
            className={clsx(
              // Basic layout
              "group/option tw-grid tw-cursor-default tw-grid-cols-[theme(spacing.5),1fr] tw-items-baseline tw-gap-x-2 tw-rounded-lg tw-py-2.5 tw-pl-2 tw-pr-3.5 sm:tw-grid-cols-[theme(spacing.4),1fr] sm:tw-py-1.5 sm:tw-pl-1.5 sm:tw-pr-3",
              // Forced colors mode
              "tw-forced-color-adjust-none forced-colors:data-[focus]:tw-bg-[Highlight] forced-colors:data-[focus]:tw-text-[HighlightText]",
              // Disabled
              "data-[disabled]:tw-opacity-50",
              // Typography
              getTypography?.(selected),
              // Prevent line breaks
              "tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap",
              // Color transitions
              "data-[focus]:tw-transition-colors data-[focus]:tw-duration-200"
            )}
          >
            <svg
              className="tw-relative tw-ml-1.5 tw-hidden tw-self-center tw-stroke-white group-data-[focus]/option:tw-block sm:tw-ml-1 sm:tw-size-3"
              viewBox="0 0 16 16"
              fill="none"
              aria-hidden="true"
            >
              <path
                d="M5 8.5l2.25 2L11 6"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className={clsx(className, sharedClasses)}>{children}</div>
          </div>
        );
      }}
    </Headless.ListboxOption>
  );
}

export function ListboxLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        "ml-2.5 truncate first:ml-0 sm:ml-2 sm:first:ml-0"
      )}
    />
  );
}

// #endregion

// #region GenericListbox

// component

export type GenericListboxProps<T> = {
  label?: string;
  description?: string;
  errors?: Record<string, string | undefined>;
  listboxClassName?: ListboxProps<T>["className"];
} & ListboxProps<T>;

export function GenericListbox<T>({
  label,
  description,
  disabled,
  errors,
  listboxClassName,
  ...props
}: GenericListboxProps<T>) {
  return (
    <Field disabled={disabled}>
      {label && <Label>{label}</Label>}
      {description && <Description>{description}</Description>}
      <Listbox
        className={listboxClassName}
        {...props}
        invalid={errors && props.name && errors[props.name] ? true : false}
      />
      {errors && props.name && errors[props.name] && (
        <ErrorMessage>{errors[props.name]}</ErrorMessage>
      )}
    </Field>
  );
}

// #endregion
